import clientStorage from "../../utils/client-storage";
import {webviewAnalyticsEvent, webviewOpenBrowser} from "../../utils/webview";
import {CryptoAnimation_v1} from "../CryptoBanners/CryptoAnimationV1/CryptoAnimation";
import {CryptoAnimation_v2} from "../CryptoBanners/CryptoAnimationV2/CryptoAnimation";

const {isPro} = window.clientConfig;
const toonGroupBannerLink = "https://t.me/toon_leaderboard_bot?start=banner-web-view";

export function getBanner(apps) {
  return filteredBanners([
    {
      value: "crypto",
      isShow: !isPro && apps.telegram,
      list: [
        {
          bannerId: "crypto_8",
          element: CryptoAnimation_v1,
          bannerProps: {
            link: toonGroupBannerLink,
          },
        },
        {
          bannerId: "crypto_9",
          element: CryptoAnimation_v2,
          bannerProps: {
            link: toonGroupBannerLink,
          }
        },
      ],
      isShowCryptoBanner: true,
      callbacks: {
        cbMount: ({value, bannerId, link}) => {
          webviewAnalyticsEvent("ad_banner_show", [
            value,
            bannerId,
            "processing",
            clientStorage.getProcessingBannerRotationIndex(value),
            clientStorage.getSelectedPhotosAmount(),
            clientStorage.getProcessedPhotosAmount(),
          ], {
            wt_npp: {
              link
            },
          });
        },
        cbClick: ({value, bannerId, link}) => {
          webviewAnalyticsEvent("ad_banner_click", [
            value,
            bannerId,
            "processing",
            clientStorage.getProcessingBannerRotationIndex(value),
            clientStorage.getSelectedPhotosAmount(),
            clientStorage.getProcessedPhotosAmount(),
          ], {
            wt_npp: {
              link
            },
          });

          webviewOpenBrowser(link);
        },
        cbUnmount: ({value, bannerId, link}) => {
          webviewAnalyticsEvent("ad_banner_close", [
            value,
            bannerId,
            "processing",
            clientStorage.getProcessingBannerRotationIndex(value),
            clientStorage.getSelectedPhotosAmount(),
            clientStorage.getProcessedPhotosAmount(),
          ], {
            wt_npp: {
              link
            },
          });
        }
      }
    },
  ]);
};

function filteredBanners(banners) {
  banners = banners.filter((banner) => banner.isShow);

  banners.forEach((_, index) => {
    if (index % 2 && banners[index + 1] && banners[index + 1].value !== "default") {
      banners.splice(index + 1, 0, banners.first());
    }
  });

  return transformBanner(banners[clientStorage.getProcessingBannerCounter() % banners.length]);
}

function transformBanner(banner) {
  const bannerParams = {};

  if (!banner) {
    bannerParams.isShowCryptoBanner = false;
    bannerParams.element = null;
    return bannerParams;
  }

  banner.list = banner.list.filter((b) => typeof b.isShow !== "undefined" ? b.isShow : b);

  if (banner.list.length === 1) {
    Object.assign(bannerParams, banner.list.first());

    if (bannerParams.callbacks) {
      bannerParams.bannerProps = {...bannerParams.bannerProps, ...bannerParams.callbacks};
    }
  } else {
    const addedProps = JSON.parse(JSON.stringify(banner));
    const rotationIndex = clientStorage.getProcessingBannerRotationIndex(banner.value);

    banner = {...banner.list[rotationIndex % banner.list.length], ...banner};
    banner.bannerProps = {
      ...banner.bannerProps,
      ...addedProps,
      ...(banner.callbacks ? {...banner.callbacks} : {})
    };

    Object.assign(bannerParams, banner);
    clientStorage.incrementProcessingBannerRotationIndex(banner.value);
  }

  return bannerParams;
}
